/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/17
 * @description
 *   in.ts of FastTradeV3
 */
import useRequest from '@/hooks/useRequest'
import { syncLocalUser } from '@/modules/user/user'
import { readBindFA, bindPayer, bindMobile } from '@/modules/user/user.api'
import { YesOrNo } from '@/types'
import { pendingTask } from 'essential/task/task.temp'
import { shallowRef } from 'vue'
import { defaultToArray, isNewVersionThan } from '@/common/common'
import * as R from 'ramda'
import { localGet } from 'essential/store/localStore'
import { keymap } from '@/config'
import { readDepositChoices, readPayParams } from '@/modules/in/in.api'
import { razorPay as _razorPay, easeBuzzPay as _easeBuzzPay, open } from '@/common/jsBridge.api'
import { useRouter } from 'vue-router'

// import { bind } from '@/pages/login/login.fns'

enum DEPOSIT_ERROR {
  NO_PAYER = 'NO_DEPOSIT_PAYER',
  NO_VERIFY = 'NO_VERIFY',
}

const trimJoin = R.pipe(R.map(R.trim), R.reject(R.isEmpty), R.join(' '), R.trim)

const formatUserInput = (data: Data) => {
  const { firstName, middleName, lastName, email } = data as Record<string, string>
  return {
    ...data,
    name: trimJoin([lastName, middleName ?? '', firstName]),
    email,
    // address: data.detail,
  }
}

export const useBindPayer = () => {
  // const route = useRoute()
  const router = useRouter()
  const [commit, progress] = useRequest(bindPayer)

  const request = async (data: Data, noMobile = false) => {
    if (noMobile) {
      const user = await bindMobile({
        ...data,
        password: Math.random().toString(16).slice(2),
      })
      syncLocalUser(user)
    }
    await commit(formatUserInput(data))
    // const autoForward = route.query.af as string
    // if (autoForward) {
    //   return router.replace({
    //     name: autoForward,
    //     query: {
    //       af: 'deposit',
    //     },
    //   })
    // } else
    router.back()
  }
  return { request, progress }
}

/**
 * 若用户没有绑定过用户信息，则弹窗填写并收集填写结果
 * 否则，调取已绑定信息
 * 若用户为Facebook登录用户，且为第一次充值，需要先绑定手机号并设置密码（随机密码）
 */
const collectPayParams = () => new Promise((resolve, reject) => {
  readBindFA().then(resp => {
    if (!resp?.name) reject(DEPOSIT_ERROR.NO_PAYER)
    // else if (!resp?.idCardImg) reject(DEPOSIT_ERROR.NO_VERIFY)
    else resolve(resp)
  }).catch(reject)
})

/**
 * 获取除金额以外的充值参数
 * @param userParams
 */
const preparePayParams = async (userParams: Data) => {
  const params = await collectPayParams()
  if (params) {
    return readPayParams(
      {
        ...(params as Data),
        ...userParams,
        pc: 1,
        phone: localGet(keymap.user.mobile),
        supportEasebuzz: YesOrNo.YES,
        supportSchema: Number(isNewVersionThan('1.0.4')),
        supportOpedBrowser: Number(isNewVersionThan('1.0.6')),
      })
  }
  return Promise.reject(new Error('Deposit params not provided'))
}

const Channels = [
  'razorpay',
  'easebuzz',
  'IPAY', // 打开url的聚合形式
] as const

type PayParam = {
  channel: typeof Channels[number];
}

const pickEaseBuzzPayParams = R.pick(
  ['txnid', 'amount', 'productinfo', 'firstname', 'email', 'phone', 'key', 'hash', 'pay_mode'])

/**
 * 根据后台返回的充值信息唤起不同的充值渠道
 * @param result
 */
const doPayAction = (result: PayParam) => new Promise((resolve, reject) => {
  const isEaseBuzz = result.channel === 'easebuzz'
  const pay = isEaseBuzz ? _easeBuzzPay : (result.channel === 'razorpay' ? _razorPay : open)
  const params = isEaseBuzz ? {
    easeBuzzParams: pickEaseBuzzPayParams(result),
  } : result
  pay(params).then(resolve).catch(reject)
})

// 充值
export const useDeposit = () => {
  const router = useRouter()
  return useRequest((data: Data) => {
    async function fn () {
      const params = await preparePayParams(data)
        .catch(e => {
          const error = e as DEPOSIT_ERROR
          if (error === DEPOSIT_ERROR.NO_PAYER) {
            const id = pendingTask(fn)
            router.push({
              name: 'fillInBaseForm',
              query: {
                pendingTaskId: id,
              },
            })
          } else if (error === DEPOSIT_ERROR.NO_VERIFY) router.push({ name: 'verify' })
          throw e
        })
      window.open(params.url, '_blank')
      // return doPayAction(params as PayParam)
    }

    return fn()
  })
}

export const useDepositChoices = () => {
  const list = shallowRef<Array<string>>([])
  readDepositChoices()
    .then(resp => list.value = defaultToArray(resp?.list))
    .catch(() => list.value = [])
  return list
}

export const getBindFormSchema = (noMobileBind: boolean) => ({
  firstName: {
    pattern: 'noSpecial',
  },
  middleName: {
    required: false,
    default: '',
  },
  lastName: {
    pattern: 'noSpecial',
  },
  email: {
    pattern: 'email',
  },
  ...(noMobileBind ? {
    countryCode: {
      pattern: 'cc',
    },
    mobile: {
      pattern: /^\d{9,12}$/,
    },
    code: {
      pattern: /^\d{4}$/,
    },
  } : {}),
})
