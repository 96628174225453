
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Radio',
  props: {
    name: String,
    value: [String, Number, Boolean],
    modelValue: [String, Number, Boolean],
  },
  emits: ['update:modelValue'],
  setup (props) {
    return {
      model: computed(() => props.modelValue),
    }
  },
})
