/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/5/14
 * @description
 *   task.temp.ts of WeTrade
 */
import * as R from 'ramda'
import idMaker from '../tools/idMaker'

const id = idMaker(n => `t_${n}`)

const tasks: Record<string, (...args: any[]) => any> = {}

export const pendingTask = <P, R> (fn: (...args: P[]) => R): string => {
  const taskId = id.next()
  tasks[taskId] = fn
  return taskId
}

export const goonTask = <P> (taskId: string, extra?: P[]) => {
  const task = tasks[taskId]
  if (task) {
    removeTask(taskId)
    return R.apply(task, (extra ?? []))
  } else {
    console.error('illegal id:', taskId, 'for pending task!')
  }
}

export const removeTask = (taskId?: string) => {
  if (taskId) delete tasks[taskId]
}
