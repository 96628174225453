/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/15
 * @description
 *   in.api.ts of WeTrade
 */
import request from '@/common/request/request'

export const readDepositChoices = request<{
  list: Array<string>;
}>('/api/recharge/amount/list')

export const readPayParams = request<{ url: string }>('/api/recharge/ipay')

export const requestRazorPay = request('/api/recharge/razorpay')

export enum InState {
  PROCESS = 1,
  SUCCESS,
  FAIL
}

export type InHistorySchema = {
  amount: number;
  createTime: number;
  orderNum: string;
  status: InState;
  rechargeChannelName: string;
}

// 充值历史记录
export const readInHistory = request<Array<InHistorySchema>>('/api/recharge/list')

export type DepositChannel = {
  channel: string;
  code: string;
  icon: string;
  id: string;
  minAmount: number;
  maxAmount: number;
}

export const readChannelList = request<DepositChannel[]>('/api/rechargechannel/countryChannellist')

export const readSkyPayCode = request<{ contractnumber: string }>(
  '/api/recharge/ipay/skypay/contractnumber')
