
import * as R from 'ramda'
import { computed, defineComponent } from 'vue'
import state from '@/state'

export default defineComponent({
  name: 'TheAsset',
  setup() {
    return {
      account: computed(() => state?.account),
      free: computed(() => R.min(state?.account?.balance || 0, state?.account?.freeMargin || 0))
    }
  }
})
