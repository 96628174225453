
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'ChoiceButton',
  components: {
    Icon
  }
})
