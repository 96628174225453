/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   In.vue of WeTrade
*/
<template>
<PageWithHeader :use-footer="useFooter">
  <template #title>
    <t path="trade_12">Deposit</t>
  </template>

  <div class="d-f pt-24">
    <div class="flex-1">
      <t as="p" path="deposit_2" class="c-title f-sm">Free margin</t>
      <TheAsset #="{freeMargin: free}">
        <money :v="free" #="{symbol, value}">
          <span class="f-nm">{{symbol}}</span>
          <span class="f-bold" style="font-size: 31px">{{value}}</span>
        </money>
      </TheAsset>
    </div>
    <div>
      <Icon name="refresh" :class="{refresh: refreshClick}" @click="refreshBalance" />
    </div>
  </div>
  <div class="wedge" style="height: 40px"></div>
  <slot />
  <div class="mt-32 mb-10 df-between">
    <Pic src="/img/in/bbb@2x.png" width="80" />
    <Pic src="/img/in/dds@2x.png" width="80" />
    <Pic src="/img/in/trustwave@2x.png" width="80" />
  </div>

  <template v-if="useFooter" #footer>
    <slot name="footer" />
  </template>
</PageWithHeader>
</template>

<script>
import Icon from '@/components/Icon.vue'
import { refreshAccount } from '@/modules/app/app'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { AccountType } from '@/state'
import { changeAccountType } from '@/state/accountType'
import Pic from 'common/Pic.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  components: { Pic, TheAsset, Icon, PageWithHeader },
  props: {
    useFooter: Boolean,
  },
  setup () {
    const refreshClick = shallowRef(false)

    changeAccountType(AccountType.REAL)

    const refreshBalance = () => {
      refreshClick.value = true
      refreshAccount()
      setTimeout(() => refreshClick.value = false, 1000)
    }

    return {
      refreshClick,
      refreshBalance,
    }
  },
})
</script>

<style scoped lang="scss">
.refresh {
  transform: rotate(360deg);
  transition: all .6s;
}
</style>
