
import { defineComponent, shallowRef } from 'vue'
import HoldOn from '@/provider/HoldOn.vue'
import Matrix from 'common/Matrix.vue'
import Icon from '@/components/Icon.vue'
import ChoiceButton from '@/components/ChoiceButton.vue'

export default defineComponent({
  name: 'BonusList',
  components: { Icon, HoldOn, Matrix, ChoiceButton },
  props: {
    list: {
      type: Object,
      require: true,
    },
    selected: {
      type: Number,
      require: true,
    },
  },
  emits: ['select'],
  setup (props, ctx) {
    const amount = shallowRef('0')

    const toggle = (item: string) => {
      amount.value = item
      ctx.emit('select', item)
    }
    return {
      amount,
      toggle,
    }
  },
})
